import {Environment, EnvironmentType} from "./environment-config";

export const environment: Environment = {
  production: true,
  environment: EnvironmentType.STAGE,
  backendBaseUrl: 'https://app.staging.propup.at/graphql',
  realFinanzWidgetBaseUrl: 'https://stage.realfinanz.at/bundles/loan-calculator-propup/loan-calculator-widget.js',
  realFinanzWidgetModuleBaseUrl: 'https://stage.realfinanz.at/bundles/loan-calculator-propup/loan-calculator.js',
  realFinanzWidgetStylingUrl: 'https://stage.realfinanz.at/bundles/loan-calculator-propup/loan-calculator.css',
  OBJECT_BACKEND_URL: 'https://api.staging.propup.at/object-service/',
  SENTRY_ENVIRONMENT: 'staging',
  googleMapsApiKey: 'AIzaSyAjwY3LeK1MdZr5mfRqX_U2nQH53Qqe0eQ',
  staticToggles: {
    enableLoggingTracing: false,
    enableLoadingPage: true,
    showFeedbackModal: true,
    showObjectImages: true,
    enableServicePage: true,
    enableOwnerSigning: true,
    enableLogin: true,
    enableIDAustria: true,
  },
}